import { gql } from "@apollo/client";

const GENERATE_LLM_CUSTOMIZATION = gql`
  mutation GenerateLLMCustomization(
    $createLlmCustomizationInput: CreateLLMCustomizationsInput!
  ) {
    generateLlmCustomizations(
      createLlmCustomizationInput: $createLlmCustomizationInput
    ) {
      ok
      result
    }
  }
`;

export default GENERATE_LLM_CUSTOMIZATION;
