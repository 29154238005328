import React from "react";
import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { NoteForm } from "../notes/edit/form/NoteForm";
import { ColorSchemePicker } from "./ColorSchemePicker";
import { formToNoteInput } from "../notes/common/utils.js";
import { alert } from "../common/Alert";
import {
  FindAndReplaceProvider,
  useAutosave,
  useDefaultValues,
} from "../../hooks";
import { EDIT_TEMPLATE } from "./mutations";
import AutosaveState from "../notes/edit/form/AutosaveState";
import mixpanel from "mixpanel-browser";

function Divider({ children }) {
  return (
    <div className="justify-self-center sticky top-14 z-20 bg-gradient-to-b from-white via-white dark:from-gray-800 dark:via-gray-800 w-full my-5">
      {children}
    </div>
  );
}

function EditableTemplate({ template }) {
  const navigate = useNavigate();

  const [editTemplate] = useMutation(EDIT_TEMPLATE);

  const methods = useForm();
  const {
    control,
    trigger,
    getValues,
    setValue,
    reset,
    resetField,
    watch,
    formState: { errors },
  } = methods;

  useDefaultValues({
    template,
    reset,
    isForTemplateEdit: true,
  });

  useAutosave({
    save: onSubmit,
    getValues,
    watch,
    control,
  });

  async function onSubmit({ data, isAutosave = false }) {
    mixpanel.track("New Typed Template");
    const structuredTemplateData = formToNoteInput({
      data,
      shouldSaveAsGenericObjective: data?.shouldUseGenericObjective,
    });

    structuredTemplateData.noteUuid = template?.note?.uuid;

    // Validate form manually
    const isValid = await trigger();
    if (!isValid && !template?.name && !isAutosave) {
      Object.values(errors).forEach((error) => {
        alert("error", error.message);
      });
      return;
    }

    if (!isValid && isAutosave) {
      return "form-errors";
    }

    const variables = {
      noteInput: { ...structuredTemplateData },
      templateUuid: template?.uuid,
      templateFields: {
        name: data.templateName,
        description: data.templateDescription || "",
        colorScheme: data?.colorScheme,
      },
    };

    editTemplate({
      variables,
    })
      .then(() => {
        if (!isAutosave) {
          alert("success", "Template saved!");
          navigate("/templates", { state: { shouldRefetch: true } });
        }
      })
      .catch((error) => {
        alert("success", error.message);
      });
  }

  return (
    <div>
      <div className="flex flex-row items-center justify-center mt-6">
        <h3 className="mr-2">Template Options</h3>
        <div className="flex-1 h-0.5 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 my-2"></div>
      </div>
      <FormProvider {...methods}>
        <div className="flex flex-row flex-wrap items-center justify-center my-3">
          <div className="flex-grow m-1">
            <Controller
              render={({ field }) => (
                <textarea
                  placeholder={"Name"}
                  className="resize-none appearance-none relative block w-full px-2 py-1 border dark:bg-gray-700 dark:border-gray-700 dark:text-gray-200 border-gray-100 bg-gray-100 placeholder-gray-400 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:z-10 text-xs md:text-sm h-8"
                  {...field}
                />
              )}
              control={control}
              rules={{
                required: "Template name is required.",
              }}
              name={"templateName"}
            />
          </div>

          <ColorSchemePicker control={control} />
        </div>
        <div className="flex-grow m-1">
          <Controller
            control={control}
            render={({ field }) => (
              <textarea
                className="resize-none appearance-none relative block w-full px-2 py-1 border dark:bg-gray-700 dark:border-gray-700 dark:text-gray-200 border-gray-100 bg-gray-100 placeholder-gray-400 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:z-10 text-xs md:text-sm"
                placeholder="Description"
                {...field}
              />
            )}
            rules={{ required: false }}
            name={"templateDescription"}
          />
        </div>
        <Divider>
          <div className="flex flex-row items-center mt-6 space-x-2">
            <h3>Template Fields</h3>
            <div className="flex-1 h-0.5 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 my-2"></div>
            <AutosaveState />
          </div>
        </Divider>

        <FindAndReplaceProvider>
          <NoteForm
            setValue={setValue}
            isTemplate={true}
            control={control}
            resetField={resetField}
          />
        </FindAndReplaceProvider>

        <div className="flex justify-center mb-10 space-x-4">
          <button
            onClick={() => {
              navigate("/templates");
            }}
            className="border border-gray-500 rounded-full px-4 py-2 text-lg font-semibold text-white bg-gray-500 hover:bg-red-500 hover:border-red-500 max-w-xs text-center my-2 transition-all focus:outline-none"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              const data = getValues();
              onSubmit({
                data,
              });
            }}
            className="border border-indigo-500 rounded-full px-4 py-2 text-lg font-semibold text-white bg-indigo-500 hover:bg-indigo-600 max-w-xs text-center my-2 transition-all focus:outline-none"
          >
            Save Template →
          </button>
        </div>
      </FormProvider>
    </div>
  );
}

export default EditableTemplate;
