import React from "react";
import { useMutation } from "@apollo/client";
import { captureException } from "@sentry/react";
import { FormControl, MenuItem, Select } from "@mui/material";
import moment from "moment";

import { ENVIRONMENT } from "../../constants.js";
import { submitTestingSampleAudio } from "../../api/UploadAudio.js";
import { useLocalStorage } from "../../hooks";
import { CREATE_QUICK_NOTE } from "../notes/edit/api/mutations.js";
import { alert } from "../common/Alert.js";
import { BasicButton } from "../common/buttons/BasicButton.js";
const medicalRecordAudio = require("../../assets/other/medical_record.wav");
const longMedicalRecordAudio = require("../../assets/other/dobby-incomplete-extended.wav");
const dentalRecordAudio = require("../../assets/other/dental_record.wav");
const dictationAudio = require("../../assets/other/dictation.wav");
const recapAudio = require("../../assets/other/recap.wav");
const callbackAudio = require("../../assets/other/callback.wav");

const testRecordings = [
  {
    jobType: "medical_record",
    value: "medical_record",
    name: "Test Medical Record",
    audioUrl: medicalRecordAudio,
  },
  {
    jobType: "medical_record",
    value: "long_medical_record",
    name: "Test Long Messy Medical Record",
    audioUrl: longMedicalRecordAudio,
  },
  {
    jobType: "dictation",
    value: "dictation",
    name: "Test Dictation",
    audioUrl: dictationAudio,
  },
  {
    jobType: "recap",
    value: "recap",
    name: "Test Recap",
    audioUrl: recapAudio,
  },
  {
    jobType: "callback",
    value: "callback",
    name: "Test Callback",
    audioUrl: callbackAudio,
  },
  {
    jobType: "dental_record",
    value: "dental_record",
    name: "Test Dental Record",
    audioUrl: dentalRecordAudio,
  },
];
const throwUnhandledError = () => {
  throw new Error("Unhandled test error from TestRecordings.js");
};

const throwHandledError = () => {
  try {
    throw new Error("Handled test error from TestRecordings.js");
  } catch (error) {
    captureException(error);
  }
};

export function TestRecordings({ data }) {
  const [createQuickNote] = useMutation(CREATE_QUICK_NOTE);

  // uselocal storage to persist testrecord-type
  const [selectedTestRecordType, setSelectedTestRecordType] =
    useLocalStorage("testrecord-type", "medical_record");

  async function createNewNote(audioStorageLink, storageObjectName) {
    let jobType = JSON.parse(localStorage.getItem("testrecord-type"));

    createQuickNote({
      variables: {
        noteInput: {
          audioStorageLink: audioStorageLink,
          storageObjectName: storageObjectName,
          createdAt: moment().format(),
          lastUpdatedAt: moment().format(),
          jobType: jobType,
        },
      },
    }).then(() => {
      alert("success", "Recording has been uploaded!");
    });

    return true;
  }

  if (
    ENVIRONMENT == "dev" ||
    ENVIRONMENT == "qa" ||
    ENVIRONMENT == "ci" ||
    data?.user?.email == "alina@scribenote.com" ||
    data?.user?.email == "ryan@scribenote.com" ||
    data?.user?.email == "ayush@scribenote.com" ||
    data?.user?.email == "george@scribenote.com" ||
    data?.user?.email == "mala@scribenote.com" ||
    data?.user?.email == "pokemonvkn@gmail.com" ||
    data?.user?.email == "greg@scribenote.com"
  ) {
    return (
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="flex flex-col ">
            <FormControl sx={{ width: 250 }} size="small">
              <Select
                sx={[
                  {
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  },
                  {
                    fontSize: "14px",
                    color: "#f9fafb",
                    backgroundColor: "#f1807e",
                    borderRadius: "8px",
                  },
                ]}
                id="test-select"
                value={selectedTestRecordType}
                onChange={(event) => {
                  setSelectedTestRecordType(event.target.value);
                }}
              >
                {testRecordings.map((recording) => {
                  return (
                    <MenuItem
                      key={recording.value}
                      sx={{ fontSize: "14px" }}
                      value={recording.value}
                    >
                      <div>{recording.name}</div>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="flex flex-col ">
            <BasicButton
              importance="secondary"
              styles="ml-2"
              onClick={() => {
                // using the selected value from the dropdown, search the testRecordings array
                // for the correct jobType and audioUrl
                let recording = testRecordings.find(
                  (recording) =>
                    recording.value == selectedTestRecordType,
                );

                const { jobType, audioUrl } = recording;

                localStorage.setItem(
                  "testrecord-type",
                  JSON.stringify(jobType),
                );

                submitTestingSampleAudio({
                  createNewNote,
                  audioUrl,
                });
              }}
            >
              <>Submit Selected</>
            </BasicButton>
          </div>
        </div>
        <div className="flex flex-row mt-2">
          <BasicButton onClick={throwHandledError}>
            Throw Handled Error
          </BasicButton>
          <BasicButton styles="ml-2" onClick={throwUnhandledError}>
            Throw Unhandled Error
          </BasicButton>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
