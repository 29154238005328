import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { NestedMenuItem } from "mui-nested-menu";
import { useDarkMode } from "../../../../hooks";

const CascadingDropdown = ({
  options,
  setIsMenuOpen,
  triggerComponent: TriggerComponent,
  menuClassName,
  title,
  isTriggerComponentDisabled = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [enabled] = useDarkMode();

  const handleOpen = (event) => {
    if (isTriggerComponentDisabled) {
      return;
    }
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const commonStyles = {
    fontSize: "14px",
    color: enabled ? "rgb(209 213 219)" : "black",
  };

  const commonStylesWithHover = {
    ...commonStyles,
    backgroundColor: enabled ? "rgb(55 65 81)" : "rgb(243 244 246)",
    "&:hover": {
      color: enabled ? "rgb(209 213 219)" : "black",
      backgroundColor: enabled ? "#2f3845" : "rgb(229 231 235)",
    },
  };

  return (
    <div
      className={`${menuClassName} absolute right-2 top-0 transform translate-x-8`}
      data-testid={`cascading-dropdown-container-${title}`}
    >
      <div
        onClick={handleOpen}
        data-testid={`trigger-component-wrapper-${title}`}
      >
        <TriggerComponent
          data-testid={`trigger-component-${title}`}
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-elevation": {
            backgroundColor: enabled
              ? "rgb(55 65 81)"
              : "rgb(243 244 246)",
            borderRadius: "8px",
          },
        }}
        componentsProps={{
          root: {
            sx: commonStyles,
          },
          list: {
            sx: commonStyles,
          },
          paper: {
            sx: commonStyles,
          },
        }}
        data-testid={`menu-${title}`}
      >
        {options?.map((option, index) =>
          option.items ? (
            <NestedMenuItem
              key={index}
              label={<span style={commonStyles}>{option.label}</span>}
              parentMenuOpen={Boolean(anchorEl)}
              MenuProps={{
                sx: {
                  ...commonStyles,
                  "& .MuiPaper-elevation": {
                    backgroundColor: enabled
                      ? "rgb(55 65 81)"
                      : "rgb(243 244 246)",
                    borderRadius: "8px",
                  },
                },
              }}
              sx={{
                ...commonStylesWithHover,
                "&.Mui-focusVisible": {
                  outline: "none",
                  backgroundColor: "inherit",
                },
                "&[tabindex='0']": {
                  tabindex: "-1",
                },
              }}
              data-testid={`customization-option-parent-${title}-${index}`}
            >
              {option.items?.map((childOption, childIndex) => (
                <MenuItem
                  key={childIndex}
                  onClick={(event) => {
                    handleClose();
                    childOption.callback &&
                      childOption.callback(event, childOption);
                  }}
                  sx={commonStylesWithHover}
                  tabIndex="-1"
                  data-testid={`customization-option-${title}-${index}-${childIndex}`}
                >
                  {childOption.label}
                </MenuItem>
              ))}
            </NestedMenuItem>
          ) : (
            <MenuItem
              key={index}
              onClick={(event) => {
                handleClose();
                option.callback && option.callback(event, option);
              }}
              sx={commonStylesWithHover}
              tabIndex="-1"
              data-testid={`customization-option-${title}-${index}`}
            >
              {option.label}
            </MenuItem>
          ),
        )}
      </Menu>
    </div>
  );
};

export default CascadingDropdown;
