import Dexie from "dexie";

export const db = new Dexie("scribenote-db");
/**
    id INTEGER PRIMARY KEY NOT null,
    saved_date TEXT,
    is_synced INTEGER,
    note_uuid TEXT
    blob BLOB,

 */
db.version(1).stores({
  unsyncedRecordings:
    "++id, saved_date, is_synced, note_uuid, blob, jobType", // Primary key and indexed props
});
