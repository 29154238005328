import React from "react";

const currentVersion = process.env.REACT_APP_VERSION;

function Footer() {
  return (
    <div className="w-full self-center rounded-t-lg bg-gray-700 dark:bg-gray-900 text-center py-3 mt-20">
      <div className="text-gray-300 dark:text-gray-500 text-xs">
        © {new Date().getFullYear()} Scribenote Inc. All rights
        reserved. v{currentVersion}
        <br />
        <div className="divide-x divide-gray-600">
          <a
            className="text-indigo-500 hover:text-indigo-400 cursor-pointer px-2"
            href="https://www.scribenote.com/terms-of-service"
          >
            Terms of Service
          </a>
          <a
            className="text-indigo-500 hover:text-indigo-400 cursor-pointer px-2"
            href="https://www.scribenote.com/privacy-policy"
          >
            Privacy Policy
          </a>
        </div>
        <div
          className="text-gray-400 dark:text-gray-600"
          style={{ fontSize: "8px" }}
        >
          Icons made by{" "}
          <a className="" href="https://icons8.com">
            Ouch.pics
          </a>
          {", "}
          <a className="" href="https://fontawesome.com">
            Font Awesome
          </a>{" "}
          | Emojis courtesy of{" "}
          <a className="" href="https://twemoji.twitter.com">
            Twemoji
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
