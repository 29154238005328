import React, { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import AttachmentSelection from "../common/inputs/AttachmentSelection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const DELETE_HEADER_IMAGE = gql`
  mutation deleteHeaderImage {
    deleteHeaderImage {
      ok
    }
  }
`;

export default function HeaderImageUpload({
  data,
  loading,
  refetch,
  isEditing,
  setIsEditing,
  control,
}) {
  const [deleteHeaderImage] = useMutation(DELETE_HEADER_IMAGE);

  function renderLogo() {
    return (
      <div className="flex flex-col items-center space-y-1">
        <div className="flex flex-row items-center justify-center relative">
          <div>
            <h4 className="text-xs text-center">
              {data?.user?.account?.logoFileLink
                ? "Current header:"
                : "Default header:"}
            </h4>
            <div className="bg-white p-1 rounded-md flex items-center justify-center shadow-sm">
              <img
                className="h-16 w-40 object-contain"
                src={
                  data?.user?.account?.logoFileLink
                    ? data?.user?.account?.logoFileLink
                    : "https://uploads-ssl.webflow.com/66203a368265774ffba79f60/66bc1f0c5af0e83d1d9c4607_scribenote-2024-svg.svg"
                }
              />
            </div>
          </div>
          {data?.user?.account?.logoFileLink && isEditing ? (
            <button
              className="bg-red-400 hover:bg-red-500 text-xs text-white px-2 py-1 rounded-full transition-colors absolute -bottom-2 self-center"
              onClick={async () => {
                await deleteHeaderImage();
                refetch();
                setIsEditing(false);
              }}
            >
              <FontAwesomeIcon icon={faTrash} size="xs" /> Delete
            </button>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-row items-center space-x-4">
      {!loading && renderLogo()}
      {isEditing && (
        <div className="flex-1">
          <AttachmentSelection
            isForNoteForm={false}
            control={control}
            acceptedFiles={"image/*"}
            shouldAcceptMultipleFiles={false}
          />
        </div>
      )}
    </div>
  );
}
