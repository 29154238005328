import { gql } from "@apollo/client";

const ACCEPT_LLM_CUSTOMIZATIONS = gql`
  mutation AcceptLlmCustomization(
    $AcceptLLMCustomizationsInput: AcceptLLMCustomizationsInput!
  ) {
    acceptLlmCustomization(
      acceptLlmCustomizationInput: $AcceptLLMCustomizationsInput
    ) {
      ok
    }
  }
`;

export default ACCEPT_LLM_CUSTOMIZATIONS;
