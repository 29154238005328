import { gql } from "@apollo/client";

export const EDIT_PREFERENCES_MUTATION = gql`
  mutation editClientSummaryPreferences(
    $frontmatter: String
    $backmatter: String
    $visitSummary: Boolean
    $abnormalPhysicalFindings: Boolean
    $vitals: Boolean
    $allFindings: Boolean
    $pointFormSummary: Boolean
    $oneLineSummary: Boolean
    $ownerInstructions: Boolean
    $medications: Boolean
    $vaccinations: Boolean
    $diagnostics: Boolean
    $diet: Boolean
    $followUpAppointments: Boolean
    $homeCare: Boolean
  ) {
    editClientSummaryPreferences(
      frontmatter: $frontmatter
      backmatter: $backmatter
      visitSummary: $visitSummary
      abnormalPhysicalFindings: $abnormalPhysicalFindings
      vitals: $vitals
      allFindings: $allFindings
      pointFormSummary: $pointFormSummary
      oneLineSummary: $oneLineSummary
      ownerInstructions: $ownerInstructions
      medications: $medications
      vaccinations: $vaccinations
      diagnostics: $diagnostics
      diet: $diet
      followUpAppointments: $followUpAppointments
      homeCare: $homeCare
    ) {
      clientSummaryPreferences {
        frontmatter
        backmatter
        visitSummary
        abnormalPhysicalFindings
        vitals
        allFindings
        pointFormSummary
        oneLineSummary
        ownerInstructions
        medications
        vaccinations
        diagnostics
        diet
        followUpAppointments
        homeCare
      }
    }
  }
`;

export const GENERATE_CLIENT_SUMMARY = gql`
  mutation generateClientSummaryNext(
    $noteUuid: String
    $subjective: String
    $objective: String
    $assessment: String
    $plan: String
    $formOptions: [String]
    $summaryType: String
    $streamingTaskUuid: String
  ) {
    generateClientSummaryNext(
      noteUuid: $noteUuid
      subjective: $subjective
      objective: $objective
      assessment: $assessment
      plan: $plan
      formOptions: $formOptions
      summaryType: $summaryType
      streamingTaskUuid: $streamingTaskUuid
    ) {
      ok
    }
  }
`;
