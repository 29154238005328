import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function CopyIndividualField({
  onClick,
  isPositionRelative,
  dataTestId,
}) {
  return (
    <button
      title="Copy text"
      className={`${
        isPositionRelative ? "" : "absolute z-10 -top-0 -right-0"
      } self-center border rounded-full w-8 h-8 bg-indigo-500 border-indigo-500 hover:bg-indigo-700 text-white transition-all font-medium outline-none text-sm shadow-md focus:outline-none`}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <FontAwesomeIcon icon={faCopy} />
    </button>
  );
}
