import React, { memo } from "react";
import twemoji from "twemoji";

const Twemoji = ({
  emoji,
  onClick = () => {},
  className = "",
  dataTestId = "",
}) => (
  <span
    className={className}
    data-testid={dataTestId}
    onClick={onClick}
    dangerouslySetInnerHTML={{
      __html: twemoji.parse(emoji, {
        base: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/",
        folder: "svg",
        ext: ".svg",
      }),
    }}
  />
);

export default memo(Twemoji);
