import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRedo,
  faFileExport,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { BasicButton } from "../../../../common/buttons/BasicButton.js";
import {
  Controller,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { EditClientSummaryResult } from "./EditClientSummaryResult.js";
import { alert } from "../../../../common/Alert.js";

const GeneratedSummary = ({
  generatedSummaryRef,
  setStep,
  handleExportClientSummary,
  isClientSummaryEditable,
  control,
  isStreaming,
  noteUuid,
  isAutoSoap,
}) => {
  const clientSummary = useWatch({ control, name: "clientSummary" });

  return (
    <>
      {isClientSummaryEditable ? (
        <EditClientSummaryResult
          control={control}
          generatedSummaryRef={generatedSummaryRef}
          noteUuid={noteUuid}
          isAutoSoap={isAutoSoap}
        />
      ) : null}
      {/* div that honours whitespace */}
      {isClientSummaryEditable ? null : (
        <div className="whitespace-pre-wrap border border-gray-100 bg-gray-50 dark:border-gray-700 dark:bg-gray-600/20 p-4 rounded-xl">
          <p
            className={`text-sm text-gray-700 ${
              isClientSummaryEditable ? "" : "cursor-wait"
            }`}
          >
            {clientSummary ? (
              clientSummary
            ) : (
              <FontAwesomeIcon
                icon={faSpinner}
                className="animate-spin text-gray-500 dark:text-gray-400"
              />
            )}
          </p>
        </div>
      )}
      <div className="flex justify-between mt-6">
        <BasicButton
          onClick={() => {
            setStep(1);
          }}
          disabled={isStreaming}
          importance="secondary"
        >
          <FontAwesomeIcon icon={faRedo} className="mr-2" />
          Restart Client Summary
        </BasicButton>

        <BasicButton
          onClick={handleExportClientSummary}
          disabled={!isClientSummaryEditable}
        >
          <FontAwesomeIcon icon={faFileExport} className="mr-2" />
          Export Client Summary
        </BasicButton>
      </div>
    </>
  );
};

export default GeneratedSummary;
