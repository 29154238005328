import { useEffect, useState } from "react";
import { useAuth } from "./use-auth";
import { useFlags } from "launchdarkly-react-client-sdk";

/**
 * If user's authentication has expired, triggers the rendering of the session expiry screen.
 * This should not happen if the user is actively navigating beween pages.
 *
 * Due to this being called in the PrivateRoute component, it will check if the user
 * the user's auth will expire within the next 2 hours every time they navigate to another page.
 *
 * Once the user is set to expire in the next two hours or less, we will reauthenticate the user
 * behind the scenes and extend their access another 24 hours. This will repeat indefinitely UNTIL
 * the user has not been active in the window near their auth expiry.
 *
 * Ex. 1: Active within reauthentication window
 * User signs in at 4pm on Monday, stays active until 8pm that day.
 * On Tuesday, they open the webapp at 2:30 pm. This is within the reauthenticate window, so their
 * access will extend until 2:30 pm on Wednesday. On Wednesday, if the first time they open Scribenote
 * is 3pm, they will see the "Session Expiry window" if the tab was open OR a login screen if its a new tab.
 *
 *
 * Ex. 2: Not active within reauthentication window
 * User signs in at 4pm on Monday, stays active until 8pm that day.
 * If they next open Scribenote again at 5pm on Tuesday,
 * they will see the "Session Expiry" modal if the tab was open OR a login screen if its a new tab.
 *
 *
 * Ex. 3: Not active within reauthentication window.
 * User signs in at 4pm on Monday, stays active until 8pm that day.
 * They next open Scribenote at 8am on Tuesday until noon. They open at again at 5pm on Tuesday.
 * They will have to reauthenticate manually. They would have only been reauthenticated automatically if
 * they were using Scribenote between 2 pm and 4 pm on Tuesday, but instead they were active until noon.
 * Thus, they are not using Scribenote when their token is almost expired, and will need to reauthenticate.
 *
 *
 * Ex. 4: Active just before reauthentication window.
 * User signs in at 4pm on Monday, stays active until 8pm that day.
 * They start using Scribenote at 1:30 PM on Tuesday and leave the tab open at 1:45.
 * They return at 5pm on Tueday. They will see a "Session expiry" modal.
 *
 *
 * Why did we choose this pattern? The thinking is that if a user is active on Scribenote,
 * they should not ever have to see a "Session expiry" window. However, if they stop being active,
 * we don't want to keep extending their access to prevent security concerns. So the thinking here
 * is that since no single action (ie, page viewed) on Scribenote should take longer than two hours,
 * then if they aren't navigating pages within 2 hours of token expiry, it is fair game to expire their auth.
 *
 * @returns {boolean} shouldShowExpiry Determines if the user should see the auth expiry window
 */
export function useAuthExpiry() {
  const auth = useAuth();

  const [shouldShowModal, setShouldShowModal] = useState(false);

  useEffect(() => {
    const isTokenExpired = auth.checkIsTokenExpired();
    const isTokenExpiringSoon = auth.checkIsTokenExpiringSoon();
    const isReauthenticating = auth.isReauthenticating;

    if (isTokenExpired) {
      setShouldShowModal(true);
    }

    if (
      isTokenExpiringSoon &&
      !isTokenExpired &&
      !isReauthenticating
    ) {
      auth.reauthenticateActiveUser();
    }
  }, [auth]);

  return { shouldShowModal };
}
