import { gql } from "@apollo/client";

const ADD_FEEDBACK_TO_CUSTOMIZATION = gql`
  mutation AddFeedbackToCustomization(
    $addFeedbackInput: AddFeedbackInput!
  ) {
    addFeedbackToCustomization(addFeedbackInput: $addFeedbackInput) {
      ok
    }
  }
`;

export default ADD_FEEDBACK_TO_CUSTOMIZATION;
