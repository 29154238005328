import { useState } from "react";
import { getTempScribenoteFlask } from "../utils";
import { alert } from "../components/common/Alert";
import { v4 as uuidv4 } from "uuid";
import mixpanel from "mixpanel-browser";

export function useStreamResult() {
  const [hasCancelEventOccurred, setHasCancelEventOccurred] =
    useState(false);
  const [isStreaming, setIsStreaming] = useState(false);

  const connectSSE = (updateCallback, finalizeCallback) => {
    const SCRIBENOTE_FLASK = getTempScribenoteFlask();

    // Critically - don't just copy / paste this code into
    // a mobile app and expect it to work.
    // With credentials relies on including http-only cookies
    // which the mobile apps currently do not support.

    // Generate a unique task UUID
    const taskUuid = uuidv4();

    //establish a connection to the stream server
    const eventSource = new EventSource(
      `${SCRIBENOTE_FLASK}/stream?channel=llm-streaming-task:${taskUuid}`,
      {
        withCredentials: true,
      },
    );
    // If the connection fails, return an error
    if (!eventSource) {
      mixpanel.track("Stream Event Source Error", {
        streamingTaskUuid: taskUuid,
      });
      return { taskUuid: "", error: true };
    }

    // If the connection fails, return an error
    if (!eventSource) {
      return { taskUuid: "", error: true };
    }

    setIsStreaming(true);

    function reset() {
      eventSource.close();
      setIsStreaming(false);
      if (finalizeCallback) {
        finalizeCallback();
      }
    }

    eventSource.onmessage = function (event) {
      const newMessage = event?.data;

      if (!newMessage) {
        return;
      }

      // new message format is {"data": " blah"} or {"event": "blah"}
      const type = JSON.parse(newMessage)?.event || "";

      if (type === "stream-end") {
        reset(); // Close the connection gracefully
        return;
      } else if (type === "stream-error") {
        alert(
          "error",
          "An error has occurred. Please try again later.",
        );
        reset(); // Close the connection on error
        return;
      } else {
        // ------- VALID MESSAGE -------
        // Parse the message to get the actual message
        const message = JSON.parse(newMessage)?.data || "";

        // Update the state to render the message in the state
        //   and also write it to whatever callback is provided

        updateCallback(message);
      }
    };

    eventSource.onerror = function () {
      reset(); // Close the connection if an error occurs
    };

    return { taskUuid, error: false };
  };

  return {
    connectSSE,
    setHasCancelEventOccurred,
    isStreaming,
  };
}
