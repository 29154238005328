import { useLiveQuery } from "dexie-react-hooks";
import mixpanel from "mixpanel-browser";
import { db } from "../../../db";
import { uploadToCloud } from "../../../api/UploadAudio";
import { createPortal } from "react-dom";
import { ConfirmModal, MessageModal } from "../modals/PopupModals";
import NoteCreatedAt from "../../notebook/note/NoteCreatedAt";
import { BasicButton } from "../buttons/BasicButton";
import PartyScribbles from "../../../assets/imgs/party-scribbles.png";
import Confetti from "../../../assets/imgs/Confetti.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudDownloadAlt,
  faDownload,
  faFileDownload,
  faSyncAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import { alert } from "../Alert";
import { saveAs } from "file-saver";
import moment from "moment";
import { ProgressBar } from "../inputs/AttachmentSelection.js";

function EmptyState() {
  return (
    <div className="mx-auto">
      <div className="flex flex-col items-center justify-center space-y-5">
        <div
          className="w-full min-h-[170px] flex-1 flex items-center justify-center"
          style={{
            backgroundImage: `url(${Confetti})`,
            backgroundRepeat: "repeat-x",
            backgroundSize: "contain",
          }}
        >
          <img
            className="flex self-center"
            width="100px"
            src={PartyScribbles}
          />
        </div>
        <h4 className="text-center text-base text-gray-500 dark:text-gray-400">
          All recordings have been synced!
        </h4>
        <h5 className=" max-w-md text-center text-sm text-gray-400 dark:text-gray-500">
          Yay! Your unsynced recordings have all been uploaded to our
          servers. You should now see them in your Inbox!
        </h5>
      </div>
    </div>
  );
}

function UnsyncedRecordingItem({
  item,
  blobURL,
  createNewNote,
  shouldShowSyncProgress,
  handleUploadProgress,
  uploadStatus,
  index,
  uploadIndex,
  setUploadIndex,
}) {
  const [
    shouldShowDeleteRecordingModal,
    setShouldShowDeleteRecordingModal,
  ] = useState(false);

  function getUploadStatus() {
    if (index == uploadIndex) {
      return uploadStatus;
    } else {
      return 0;
    }
  }

  async function handleSync(index) {
    setUploadIndex(index);
    uploadToCloud({
      blob: item.blob,
      handleUploadProgress,
      createNewNote,
      jobType: item.jobType,
    })
      .then((response) => {
        if (response?.success == true) {
          db.unsyncedRecordings
            .where({ id: item.id })
            .modify((r) => (r.is_synced = true));
          alert(
            "success",
            "Recording successfully synced to our cloud servers.",
          );
        }
      })
      .catch((error) => {
        alert("warning", "Unable to upload. Saved offline for now.");
      });
  }

  return (
    <>
      {createPortal(
        <ConfirmModal
          shouldShow={shouldShowDeleteRecordingModal}
          hideModal={() => setShouldShowDeleteRecordingModal(false)}
          title={"Delete this recording?"}
          confirmText="Yes, delete"
          cancelText="Cancel"
          confirmAction={() => {
            db.unsyncedRecordings.delete(item.id);
            alert("success", "Recording deleted.");
            setShouldShowDeleteRecordingModal(false);
          }}
          cancelAction={() =>
            setShouldShowDeleteRecordingModal(false)
          }
        >
          <h5>
            Are you sure you&apos;d like to delete this recording?
          </h5>
        </ConfirmModal>,
        document.body,
      )}

      <div className="p-3 bg-gray-50 border border-gray-100 dark:border-gray-600 dark:bg-gray-700 rounded-lg self-center flex flex-col transition-all space-y-3">
        <div className="flex-1 flex flex-row items-center justify-between">
          <NoteCreatedAt createdAt={item.saved_date} />
          <div className="flex flex-row items-center space-x-1">
            <div
              className={`${
                item?.is_synced
                  ? "border-green-500 text-green-500 "
                  : "border-red-500 text-red-500"
              } text-xs border rounded-full px-2 py-1 items-center justify-end self-end`}
            >
              {item?.is_synced ? "SYNCED" : "UNSYNCED"}
            </div>
            <BasicButton onClick={() => handleSync(item?.id)}>
              <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />{" "}
              {shouldShowSyncProgress
                ? "Syncing..."
                : item?.is_synced
                ? "Re-sync"
                : "Sync"}
            </BasicButton>
          </div>
        </div>

        <audio controls className="flex-1 rounded-lg">
          <source src={blobURL} type="audio/wav" />
          Your browser does not support the audio element.
        </audio>

        <div className="flex-1 flex flex-row items-center space-x-2">
          <button
            className="flex items-center justify-center w-8 h-8 rounded-full bg-red-400 hover:bg-red-500"
            onClick={() => {
              setShouldShowDeleteRecordingModal(true);
            }}
          >
            <FontAwesomeIcon className="text-white" icon={faTimes} />{" "}
          </button>

          <div className="flex-1">
            {item?.is_synced ? (
              <ProgressBar
                width="w-full"
                uploadIndex={uploadIndex}
                index={item?.id}
                uploadProgress={100}
              />
            ) : (
              <ProgressBar
                width="w-full"
                uploadIndex={uploadIndex}
                index={item?.id}
                uploadProgress={getUploadStatus()}
              />
            )}
          </div>

          <button
            className="flex items-center justify-center w-8 h-8 rounded-full bg-indigo-500 hover:bg-indigo-600"
            onClick={() =>
              saveAs(
                item?.blob,
                `Scribenote-Recording-${moment(
                  item?.saved_date,
                ).format("MM-DD-YYYY-h-mm-a")}.wav`,
              )
            }
          >
            <FontAwesomeIcon
              icon={faDownload}
              size="xs"
              className="text-white"
            />
          </button>
        </div>
      </div>
    </>
  );
}

export default function UnsyncedRecordings({
  shouldShowUnsyncedRecordings,
  setShouldShowUnsyncedRecordings,
  createNewNote,
}) {
  const [uploadStatus, setUploadStatus] = useState(0);
  const [uploadIndex, setUploadIndex] = useState(null);

  const allItems = useLiveQuery(
    () => db.unsyncedRecordings.toArray(),
    [uploadStatus],
  );

  useEffect(() => {
    if (!mixpanel || allItems === undefined) return;

    const unsyncedRecordingsCount = allItems?.length;
    mixpanel.track("Sync Recordings Modal Viewed", {
      unsyncedRecordingsCount: unsyncedRecordingsCount,
    });
  }, [mixpanel, allItems]);

  function handleUploadProgress(progressEvent) {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );

    setUploadStatus(percentCompleted);
  }

  function renderUnsyncedRecordings() {
    if (allItems?.length > 0) {
      return allItems
        ?.filter((item) => item?.blob)
        .map((item) => {
          const blobURL = URL.createObjectURL(item.blob);
          return (
            <div key={item.id}>
              <UnsyncedRecordingItem
                item={item}
                blobURL={blobURL}
                createNewNote={createNewNote}
                uploadIndex={uploadIndex}
                setUploadIndex={setUploadIndex}
                uploadStatus={uploadStatus}
                handleUploadProgress={handleUploadProgress}
              />
            </div>
          );
        });
    } else {
      return <EmptyState />;
    }
  }

  if (document.readyState === "complete") {
    return createPortal(
      <MessageModal
        shouldShow={shouldShowUnsyncedRecordings}
        setShouldShow={setShouldShowUnsyncedRecordings}
        title="Unsynced Recordings"
        subtitle={
          allItems?.length > 0 ? (
            <span>
              Syncing recordings will upload them to our servers to be
              processed. <br /> A stable internet connection is
              required to sync recordings.
            </span>
          ) : null
        }
        dismissable
      >
        <div className="pb-10 space-y-3">
          {renderUnsyncedRecordings()}
        </div>
      </MessageModal>,
      document.getElementById("root-node"),
    );
  }
}
