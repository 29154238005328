import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { captureException } from "@sentry/react";

import mixpanel from "mixpanel-browser";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import {
  faBars,
  faMicrophone,
  faStop,
  faPaw,
  faEdit,
  faCheck,
  faTimes,
  faClock,
  faWeight,
} from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import App from "./components/layout/App";
import "./index.css";
import "react-circular-progressbar/dist/styles.css";
import {
  SCRIBENOTE_GRAPHQL,
  ENVIRONMENT,
  INTERCOM_ID,
  INTERCOM_ENABLED,
} from "./constants.js";
import {
  AccountProvider,
  AuthProvider,
  NoteUsageProvider,
  PreferencesProvider,
} from "./hooks";
import { createTheme, ThemeProvider } from "@mui/material";
import { IntercomProvider } from "react-use-intercom";
import { CurrentUserProvider } from "./hooks/use-current-user";
import { ScribenoteGlobalContextProvider } from "./context/scribenoteGlobalContext";
import { WatchNoteUpdatesProvider } from "./hooks";
import { TeamProvider } from "./hooks/use-team";
import { TeamMemberProvider } from "./hooks/use-team-member";

/** BEGIN SENTRY CONFIG */
if (ENVIRONMENT !== "dev") {
  Sentry.init({
    dsn: "https://2c03e9d93b444b8281f7d2ddce8c8eab@o362489.ingest.sentry.io/4215838",
    environment: ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration()],

    tracesSampleRate: 1.0,
  });
  //Set the web app version
  Sentry.setTag("web_app_version", process.env.REACT_APP_VERSION);
}

/** END SENTRY CONFIG */
let MIXPANEL_ID = "2843f411f4738d1a581a578f593c4311";
if (ENVIRONMENT == "prod") {
  MIXPANEL_ID = "f38437e64ec9df766e801e31f06fe129";
} else if (ENVIRONMENT === "qa") {
  MIXPANEL_ID = "4a601c05607c5442d1456c4812f4c577";
}

let LD_CLIENT_KEY = "";

if (ENVIRONMENT == "prod") {
  LD_CLIENT_KEY = "64e68a8c8fe28013699d2676";
} else {
  LD_CLIENT_KEY = "64e68a8c8fe28013699d2675";
}

mixpanel.init(MIXPANEL_ID, {
  debug: true,
  persistence: "localStorage",
});

mixpanel.register({
  "Web App Version": process.env.REACT_APP_VERSION,
});

/** BEGIN APOLLO CLIENT CONFIG */
const httpLink = createHttpLink({
  credentials: "include",
});

// Manage global auth headers through authLink
const authLink = setContext(async (_, { headers }) => {
  // Need to use the csrf session token that comes back on
  // Auth steps and include it in all requests.

  const csrf_token = localStorage.getItem("accessCsrf");

  // Used for rerouting to the correct API host
  // Interfaces through localstorage to allow for proper ordering of
  // hooks + context + launchdarkly user identification (needs auth to be set first)

  // don't use till csrf issue fixed
  // const URI =
  //   localStorage.getItem("SCRIBENOTE_API_HOST") + "/graphql" ||
  //   SCRIBENOTE_GRAPHQL;

  return {
    uri: SCRIBENOTE_GRAPHQL,
    headers: {
      ...headers,
      "X-CSRF-Token": csrf_token,
      "X-SCRIBENOTE-USER-DEVICE": "web",
    },
  };
});

// Manage global graphQLErrors through errorlink
// This works with the Error Context to broadcast messages to
// our error component. Messages are pushed into the errors list,
// which is passed to error context
const errorLink = onError(({ graphQLErrors, networkError }) => {
  function kickEmOut(message, locations, path) {
    // Check if the page has already been reloaded
    if (localStorage.getItem("already_reloaded")) {
      // If it has, stop the reload loop
      return;
    }
    captureException(new Error(message), {
      extra: {
        locations,
        path,
      },
    });
    // Set the flag in localStorage to indicate that the page has reloaded
    localStorage.setItem("already_reloaded", "true");

    // Unauthenticate the user in the event that auth is failing.
    localStorage.removeItem("user_first_name");
    localStorage.removeItem("is_user_authenticated");
    localStorage.removeItem("accessCsrf");
    localStorage.removeItem("auth_expiry");
    localStorage.removeItem("org_uuid");
    localStorage.removeItem("currentTeam");
    localStorage.removeItem("user_uuid");

    // Reload the page
    window.location.reload();
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
      if (message === "Signature verification failed") {
        kickEmOut(message, locations, path);
      } else if (message === "Signature has expired") {
        kickEmOut(message, locations, path);
      } else if (
        message === "400 Bad Request: The CSRF tokens do not match."
      ) {
        kickEmOut(message, locations, path);
      } else if (
        message === "400 Bad Request: The CSRF token is invalid."
      ) {
        kickEmOut(message, locations, path);
      } else if (
        message === "400 Bad Request: The CSRF token has expired."
      ) {
        kickEmOut(message, locations, path);
      } else if (
        message ===
        'Missing JWT in cookies or headers (Missing cookie "access_token_cookie"; Missing Authorization Header)'
      ) {
        if (window.location.pathname !== "/") {
          kickEmOut(message, locations, path);
        }
      } else if (
        message === "Token has been revoked" &&
        (window.location.pathname === "/account" ||
          window.location.pathname.includes("/admin"))
      ) {
        window.location.reload();
      }

      console.log(message);
      // The CSRF session token is missing.
      // Do the same for other error types
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const mergePaginatedNotes = (
  existing,
  incoming,
  { args: { offset = 0 } },
) => {
  // Slicing is necessary because the existing data is
  // immutable, and frozen in development.

  // only do this on notes
  if (incoming?.notes) {
    const merged = existing ? existing.notes.slice(0) : [];
    for (let i = 0; i < incoming.notes.length; ++i) {
      merged[offset + i] = incoming.notes[i];
    }
    return {
      ...incoming,
      notes: merged,
    };
  } else {
    return incoming;
  }
};

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        dashboardInboxNotes: {
          keyArgs: [],
          merge: mergePaginatedNotes,
        },
        inboxNotes: {
          keyArgs: [],
          merge: mergePaginatedNotes,
        },
      },
    },
  },
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: cache,
  credentials: "include",
});
/** END APOLLO CLIENT CONFIG */

library.add(
  faCopy,
  faBars,
  faMicrophone,
  faStop,
  faPaw,
  faEdit,
  faCheck,
  faTimes,
  faWeight,
);

toast.configure();

const contextClass = {
  success: "bg-green-600",
  error: "bg-red-600",
  info: "bg-gray-600",
  warning: "bg-yellow-500",
  default: "bg-indigo-600",
  dark: "bg-gray-600 text-gray-100",
};

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Inter",
    },
  },
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LD_CLIENT_KEY,
  });

  // AccountProvider MUST wrap AuthProvider since AuthProvider requires the
  // useAccount() hook
  ReactDOM.render(
    <LDProvider>
      <ScribenoteGlobalContextProvider>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <IntercomProvider
              appId={INTERCOM_ID}
              shouldInitialize={INTERCOM_ENABLED}
              autoBoot
            >
              <AccountProvider>
                <AuthProvider>
                  <CurrentUserProvider>
                    <TeamMemberProvider>
                      <TeamProvider>
                        <WatchNoteUpdatesProvider>
                          <NoteUsageProvider>
                            <PreferencesProvider>
                              <ThemeProvider theme={theme}>
                                <App />
                                <ToastContainer
                                  className={"pb-14"}
                                  toastClassName={({ type }) =>
                                    contextClass[type || "default"] +
                                    " my-2 relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
                                  }
                                  bodyClassName={() =>
                                    "text-sm font-white font-med block p-3"
                                  }
                                  position="bottom-right"
                                  autoClose={3000}
                                  hideProgressBar={false}
                                  newestOnTop={false}
                                  closeOnClick
                                  rtl={false}
                                  pauseOnFocusLoss
                                  draggable
                                  pauseOnHover
                                />
                              </ThemeProvider>
                            </PreferencesProvider>
                          </NoteUsageProvider>
                        </WatchNoteUpdatesProvider>
                      </TeamProvider>
                    </TeamMemberProvider>
                  </CurrentUserProvider>
                </AuthProvider>
              </AccountProvider>
            </IntercomProvider>
          </BrowserRouter>
        </ApolloProvider>
      </ScribenoteGlobalContextProvider>
    </LDProvider>,
    document.getElementById("root"),
  );
})();
